import React from 'react'
import '../styles/template1.css'

const CloudStrategyContent = props => {
  return (
    <div className="information-block">
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '32px auto',
          columnGap: '15px',
        }}
      >
        <span
          style={{
            backgroundColor: 'rgba(22,123,250,.3)',
            borderRadius: '50px',
            height: '32px',
            fontFamily: 'ElegantIcons',
            textAlign: 'center',
            lineHeight: '2',
            color: '#167bfa',
          }}
        >
          {props.icon}
        </span>
        <h4 className="block-title h4">{props.blockTitle}</h4>
        <div></div>
        <p className="block-text">{props.blockText}</p>
      </div>
    </div>
  )
}

export default CloudStrategyContent
